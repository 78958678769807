<template>
  <div class="frontend w-100 d-flex justify-content-center align-items-center vh-100">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <div class="page-content mx-auto my-auto d-none">
      <b-button
        variant="primary"
        @click="singpassLogin"
      >
        Singpass Login
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from './Loader.vue'

export default {
  components: {
    BButton,
    Loader,
  },
  mounted() {
    this.singpassLogin()
  },
  methods: {
    singpassLogin() {
      const formData = new FormData()
      let purpose = 'onboarding'
      if (this.$route.query.purpose) {
        purpose = this.$route.query.purpose
      }
      formData.append('purpose', purpose)
      if (this.$route.query.token) {
        formData.append('token', this.$route.query.token)
      }
      let postUrl = 'front-end/singpass/login'
      if (purpose === 'onboarding') {
        postUrl = 'front-end/singpass/short/info'
      } else if (purpose === 'myinfo') {
        postUrl = 'front-end/singpass/myinfo'
      }

      console.log(postUrl)

      this.$http.post(postUrl, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          localStorage.setItem('singpassAuthorizationSession', response.data.sessionID)
          window.location.href = response.data.authorizationUrl
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
  ._hj-widget-container {
    display: none !important;
  }
</style>
